<template>
  <!-- 商品档案子组件-商品资料库 -->
  <div>
    <!-- 功能区 -->
    <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>
    <!-- 文件 -->
    <div class="file_form">
      <!-- 文件夹处 -->
      <div class="file">
        <div class="file_level1" @click="isFileShow = !isFileShow">
          <img v-if="!isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹加.png')"
            alt="" />
          <img v-if="isFileShow" style="width: 10px; height: 10px" :src="require('@/assets/mianAssets/文件夹减.png')"
            alt="" />
          <img style="width: 22px; height: 22px; margin-left: 5px" :src="require('@/assets/mianAssets/文件夹.png')" alt="" />
          <a href="#">全部文件</a>
        </div>
        <div class="file_level2" v-if="isFileShow">
          <ul>
            <li v-for="(file, index) in filStorages" :key="file" style="display: flex; align-items: center"
              @click="fileClick(index)">
              <img style="width: 12px; margin-right: 3px" :src="require('@/assets/mianAssets/空白页.png')" alt="" /><a
                href="#" :title="file">
                <span :class="isLabel == index ? 'label' : ''">{{ file }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 表格 -->
      <div style="padding: 10px 0; width: 100%">
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" align="center"> </el-table-column>
          <el-table-column prop="pic" label="图片" align="center">
          </el-table-column>
          <el-table-column prop="uid" sortable label="编号" align="center">
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="unit" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="marketPrice" sortable label="市场价" align="center">
          </el-table-column>
          <el-table-column prop="salesPrice" sortable label="销售价" align="center">
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span v-if="isResult">显示第&nbsp;{{ (currentPage - 1) * 10 + 1 }}&nbsp;至&nbsp;{{
            (currentPage - 1) * 10 + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tabletotalNum }}&nbsp;项</span>
          <span v-if="!isResult">显示&nbsp;0&nbsp;项结果，共&nbsp;0&nbsp;项</span>
          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-pagination background layout="prev, pager, next" :total="tabletotalNum" :current-page="currentPage"
              @current-change="currentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImportGoods",
  data() {
    return {
      searchData: {
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
        isDaoRuBtn: true,
      },

      formInline: {
        inquire: "",
      },
      isFileShow: false,
      isLabel: -1,
      isPutaway: false,
      filStorages: ["到店自提", "自提水票", "配送专区"],

      tableData: [],
      multipleSelection: [],
      isResult: true,
      tabletotalNum: 0,
      currentPage: 1,
      currentLength: 10,
      perPage: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    // 多选数据 -- 表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页 -- 当前页改变传值
    currentChange(val) {
      this.currentPage = val;
      this.inquireList();
    },
    // 表格数据
    inquireList() {
      // this.$http
      //   .post(
      //     "/product/lst",
      //     this.$qs.stringify({
      //       currentPage: this.currentPage,
      //       currentLength: this.currentLength,
      //     })
      //   )
      //   .then((res) => {
      //     this.isLoading = false;
      //     this.tableData = res.data.data;
      //     this.tabletotalNum = res.data.count;
      //   });
    },
    // 查询 - 导入 
    inquireBtnClick(e) {
      console.log(e.inquireValue);
    },
    fileClick(i) {
      this.isLabel = i;
      console.log("文件选中" + i);
    },
  },
};
</script>
<style scoped lang="less">
.icon {
  position: relative;
}

.icon:hover {
  color: red;
}

.file_form {
  display: flex;
  justify-content: space-between;

  .file {
    padding: 20px;

    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.tableBottom {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.label {
  background-color: #ffeabf;
}
</style>

<style lang="less">
.demo-form-inline .el-form-item:nth-child(n) {
  padding: 0 3px;
}
</style>
